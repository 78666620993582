import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import '../css/LandingPage.css'; // Make sure to create a corresponding CSS file
import '../'

const LandingPage = () => {
    const navigate = useNavigate();  // Create an instance of useNavigate

    const handleLogin = () => {
        navigate('/signin'); // Navigate to /signin when login is clicked
    };

    const handleGetStarted = () => {
        navigate('/signup'); // Navigate to /signup when get started is clicked
    };

    return (
        <div className="landing-page">
            <Link to="/">
                <img src="/Logo.png" alt="Company Logo" className="company-logo" />
            </Link>
            <div className="landing-body">
                <div className="image-section">
                    <img src="/Demo.gif" alt="Company Logo" className="demo-image"/>
                </div>
                <header className="header">
                    <div className="search-section">
                        <h1>Scrape Web Pages from Google</h1>
                        <p>Enter your search term just like on google and we'll scrape contact data from
                        pages that appear from the google search.</p>
                        <div className="buttons">
                            <button className="login-btn" onClick={handleLogin}>Login</button>
                            <button className="get-started-btn" onClick={handleGetStarted}>Get Started</button>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    );
};

export default LandingPage;
