import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { FiChevronDown, FiChevronUp, FiInfo } from "react-icons/fi";
import ToggleSwitch from "../components/ToggleSwitch";
import BillingModal from "../components/Biling";
import axios from 'axios';
import '../css/SearchPage.css';
import { signOut } from '@aws-amplify/auth';

const SearchPage = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchAllStates, setSearchAllStates] = useState(false);
    const [searchAllCounties, setSearchAllCounties] = useState(false);
    const [grabPersonalEmail, setGrabPersonalEmail] = useState(false);
    const [resultsPerSearch, setResultsPerSearch] = useState(20);
    const [model, setModel] = useState('GPT 3.5');
    const [totalPages, setTotalPages] = useState(0);
    const [price, setPrice] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [showError, setShowError] = useState(false);




    useEffect(() => {
        calculatePages();
    }, [searchAllStates, searchAllCounties, resultsPerSearch]);

    useEffect(() => {
        calculatePrice();
    }, [model, totalPages,grabPersonalEmail]);

    const handleToggleStates = () => {
        setSearchAllStates(!searchAllStates);
        if (!searchAllStates) {
            setSearchAllCounties(false);  // Ensure correct toggle behavior
        }
    };


    const handleToggleCounties = () => {
        setSearchAllCounties(!searchAllCounties);
        if (!searchAllCounties) {
            setSearchAllStates(true);
        }
    };
   const handleTogglePersonal = () => {
        setGrabPersonalEmail(!grabPersonalEmail);
    };


    const calculatePages = () => {
        let basePages = parseInt(resultsPerSearch, 10);
        if (searchAllStates && !searchAllCounties) {
            setTotalPages(basePages * 50);
        } else if (searchAllCounties) {
            setTotalPages(basePages * 3145);
        } else {
            setTotalPages(basePages);
        }
    };

    const calculatePrice = () => {
        let perPageCost = model === 'GPT 3.5' ? 0.03 : 0.05;
        perPageCost = grabPersonalEmail ? perPageCost * 0.6 : perPageCost;
        let p = (perPageCost * totalPages);
        console.log('p',p)
        let personalMultiplier = grabPersonalEmail ? .05 : 0;
        p = p + totalPages * personalMultiplier;
        if (p < 0.50) {
            p = 0
        }
        setPrice(p.toFixed(2));
    };

    useEffect(() => {
        if (searchQuery.trim()) {
            setShowError(false);
        }
    }, [searchQuery]);

    const handleFormSubmit = (event) => {
        console.log('Form Submitted')
        event.preventDefault();
        if (!searchQuery.trim()) {  // Check if the searchQuery is empty or just whitespace
            setShowError(true);  // Show the error message
        } else {
            handleSearch();  // Proceed with the search function if no error
        }
    };
    const handleSearch = () => {
        setShowError(false); // Ensure error is reset upon valid search
        setShowModal(true);  // Show modal for further actions
    };

    const fetchPaymentUrl = useCallback(async () => {
        const url = "https://api.kai-search.com/create-checkout-session";
        const loopset = searchAllStates ? "states" : (searchAllCounties ? "counties" : "");
        const priceInCents = Math.round(price * 100); // Assuming price is in dollars
        const req_model = model === 'GPT 3.5' ? 'gpt-3.5-turbo-0125' : 'gpt-4-turbo';
        const req_resultsPerSearch = parseInt(resultsPerSearch, 10)
        const domain = grabPersonalEmail;
        try {
            if (priceInCents > 50 && !searchQuery.includes('cmh96')){
                const response = await axios.post(url, {
                    amount: priceInCents,
                    currency: 'usd',
                    term: searchQuery,
                    results_per_page: req_resultsPerSearch,
                    loop_set: loopset,
                    model: req_model,
                    session_id: sessionId,
                    base_domain:window.location.origin,
                    domain:domain
                });
                const data = response.data;
                if (data.paymentUrl) {
                    console.log('price', priceInCents)
                    setSessionId(data.sessionId);
                    window.location.href = data.paymentUrl; // Correctly navigate to the Stripe Checkout
                }
            }else{
                console.log('Price is less than $0.50')
                navigate('/loading?term=' + searchQuery + '&model=' + req_model + '&results_per_page=' +
                    req_resultsPerSearch + '&loop_set=' + loopset+'&domain='+domain);
            }
        } catch (error) {
            console.error('Failed to create payment session:', error);
        }
    }, [price, searchQuery, resultsPerSearch, searchAllStates, searchAllCounties, model, sessionId]);

    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/signin'); // Redirect to sign-in page after signing out
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };


    return (
        <div className="search-page">
            <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>

            <div className="search-container">
                <form onSubmit={handleFormSubmit}>
                    <div className="subheading" style={{ display: 'flex', alignItems: 'center' }}>
                        Search
                        <div className="tooltip">
                            <FiInfo />
                            <span className="tooltiptext">Enter a type of company.
                            <br/><br/>
                            This is similar to what you might enter on google when searching for these companies.
                            <br/><br/>
                            i.e. "Roofing Companies" or "Plumbing Companies New York"</span>
                        </div>
                    </div>
                    <div className="search-bar">
                        <input
                            type="text"
                            autoComplete="off"
                            placeholder="Search for companies..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    {showError && <div className="error-message">Please enter a term to search.</div>}
                    <div className="options-heading">Options</div>
                    <div className="option">
                        <div className="label-with-tooltip">
                            <label>Search All States</label>
                            <div className="tooltip">
                                <FiInfo />
                                <span className="tooltiptext">Search for term across all 50 states.</span>
                            </div>
                        </div>
                        <ToggleSwitch checked={searchAllStates} onChange={handleToggleStates}/>
                    </div>
                    <div className="option">
                        <div className="label-with-tooltip">
                            <label>Search All Counties</label>
                            <div className="tooltip">
                                <FiInfo />
                                <span className="tooltiptext">Search for term in all 3K+ U.S. counties.</span>
                            </div>
                        </div>
                        <ToggleSwitch checked={searchAllCounties} onChange={handleToggleCounties}/>
                    </div>
                    <div className="option">
                        <div className="label-with-tooltip">
                            <label>Grab Personal Emails</label>
                            <div className="tooltip">
                                <FiInfo />
                                <span className="tooltiptext">Check for personal emails</span>
                            </div>
                        </div>
                        <ToggleSwitch checked={grabPersonalEmail} onChange={handleTogglePersonal}/>
                    </div>
                    <div className="option option-column">
                        <div className="label-with-tooltip">
                            <label>Pages per Search</label>
                            <div className="tooltip">
                                <FiInfo />
                                <span className="tooltiptext">Number of pages to check for each search result.</span>
                            </div>
                        </div>
                        <div className="number-input-container">
                            <input
                                type="number"
                                value={resultsPerSearch}
                                onChange={(e) => setResultsPerSearch(Math.max(1, Number(e.target.value)))}
                                min={1} // Dynamically set the HTML min attribute
                            />
                            <div className="icon-container">
                                <FiChevronUp className="icon" onClick={() => setResultsPerSearch(prev => Math.max(1, prev + 1))}/>
                                <FiChevronDown className="icon" onClick={() => setResultsPerSearch(prev => Math.max(1, prev - 1))}/>
                            </div>
                        </div>
                    </div>
                    <div className="option option-column">
                        <div className="label-with-tooltip">
                            <label>Model</label>
                            <div className="tooltip">
                                <FiInfo />
                                <span className="tooltiptext">We use a LLM to extract the data. The better the model, the higher quality the extraction.</span>
                            </div>
                        </div>
                        <select
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                        >
                            <option value="GPT 4">GPT 4</option>
                            <option value="GPT 3.5">GPT 3.5</option>
                        </select>
                    </div>
                    <div className="search-button-container">
                        <div className="footer-info">
                            <div># Pages: {totalPages}</div>
                            <div>Price: ${price}</div>
                        </div>
                        <button type="submit" className="search-button">
                            Search
                        </button>
                    </div>
                </form>
                {showModal && <BillingModal totalPrice={price} onConfirm={fetchPaymentUrl} onCancel={() => setShowModal(false)} />}
            </div>
        </div>
    );

};

export default SearchPage;