const BillingModal = ({ totalPrice, onConfirm, onCancel }) => {
    const formattedTotalPrice = Number(totalPrice).toFixed(2);

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Confirm Your Purchase</h2>
                <p>The number of pages is an estimate. Please confirm the final price below:</p>
                <p><strong>Price:</strong> ${formattedTotalPrice}</p>
                <div className="modal-buttons">
                    <button onClick={onCancel} className="modal-cancel-button">Cancel</button>
                    <button onClick={onConfirm} className="modal-confirm-button">Confirm Purchase</button>
                </div>
            </div>
        </div>
    );
};

export default BillingModal;
