import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserProvider } from './components/UserContext';

// Configure AWS Amplify
Amplify.configure(awsExports);

// Load your Stripe public key
const stripePromise = loadStripe('pk_live_51JeKlPIqibMDMPxmxdevaoODIqIgJMtpzvZ9BlCHBZ2EZ10zzcra0nH98BYxk7sXXWW1Px64YHuNIcTQad3yOEJ8006hOXeSkG');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <UserProvider>
            <Elements stripe={stripePromise}>
                <App />
            </Elements>
        </UserProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
