import React from 'react';
import '../css/DownloadPage.css';
import { useLocation } from 'react-router-dom';

const ExportReady = () => {
    const location = useLocation();
    const downloadUrl = location.state?.downloadUrl;

    const handleDownload = () => {
        if (downloadUrl) {
            window.location.href = downloadUrl; // Trigger file download via the URL
        }
    };

    return (
        <div className="export-wrapper">
            <div className="export-container">
                <h2>Your export is ready!</h2>
                <p>You can download your data as a .csv file below. If you have any questions, don't hesitate to reach out to us at <a href="mailto:support@kai-search.com">support@kai-search.com</a>.</p>
                <button className="download-btn" onClick={handleDownload}>
                    Download CSV
                </button>
            </div>
        </div>
    );
};

export default ExportReady;
