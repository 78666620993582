import React from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';

const signUpConfig = {
    header: 'Create a New Account',
    initialAuthState: "signup",
    signUpFields: [
        { label: 'Email', key: 'username', required: true, displayOrder: 1, type: 'email' },
        { label: 'Password', key: 'password', required: true, displayOrder: 2, type: 'password' },
        { label: 'Name', key: 'name', required: true, displayOrder: 3, type: 'text' }
    ]
};

const SignUpContent = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();

    return (
        <>
            {user ? (
                <div>
                    <h1>Welcome, {user.username}</h1>
                    <button onClick={() => signOut()}>Sign out</button>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

const SignUpPage = () => {
    return (
        <Authenticator initialState="signUp" signUpConfig={signUpConfig}>
            <SignUpContent />
        </Authenticator>
    );
};

export default SignUpPage;
