import React from 'react';
import './css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SearchPage from "./pages/SearchPage";
import LoadingPage from "./pages/LoadingScreen";
import DownloadPage from "./pages/DownloadPage";
import LandingPage from "./pages/LandingPage";
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import Cancel from './pages/Cancel';


const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/signin" element={<SignInPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/loading" element={<LoadingPage />} />
                <Route path="/download" element={<DownloadPage />} />
                <Route path="/cancel" element={<Cancel />} />
            </Routes>
        </Router>
    );
};

export default App;
