// utils/auth.js
import { getCurrentUser } from '@aws-amplify/auth';

export async function getCurrentUserEmail() {
    try {
        const userData = await getCurrentUser();
        if (userData) {
            return userData;
        } else {
            throw new Error('No authenticated user');
        }
    } catch (error) {
        console.error('Error fetching user details:', error);
        return null;
    }
}
