import React, { useState, useEffect, useRef } from 'react';
import '../css/LoadingScreen.css';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useUser} from "../components/UserContext";
import {getCurrentUserEmail} from "../utils/auth";
const LoadingScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchStatus, setSearchStatus] = useState("Initializing search...");
    const [statStep, setStatStep] = useState(0);
    const [statTxt, setStatTxt] = useState("Processing your search results...");
    const [downloadUrl, setDownloadUrl] = useState("");
    const [jobId, setJobId] = useState("");  // Use `const` for state declarations
    const [respEmail, setRespEmail] = useState("");  // Use `const` for state declarations
    const searchInitialized = useRef(false); // useRef to track if search has been initialized
    const [userEmail, setUserEmail] = useState('');
    const [emailCheck, setEmailCheck] = useState(false);


    const fetchEmail = async () => {
        try {
            const user = await getCurrentUserEmail();
            setUserEmail(user.signInDetails.loginId);
            setEmailCheck(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };


    useEffect(() => {
        fetchEmail();
        if (!emailCheck) return;
        const query = new URLSearchParams(location.search);
        const sessionId = query.get('session_id');
        const model = query.get('model');
        const resultsPerSearch = query.get('results_per_page');
        const loopSet = query.get('loop_set');
        const term = query.get('term');
        const domain = query.get('domain');
        if (term && !searchInitialized.current) {
            searchInitialized.current = true; // Set to true to block further executions
            console.log(userEmail)
            executeSearch({ sessionId, model, resultsPerSearch, loopSet, term,domain });
        } else {
            setSearchStatus("Fetching search progress...");
        }
    }, [location.search,emailCheck]); // Only re-run this effect if the search part of the URL changes


    useEffect(() => {
        const interval = setInterval(() => {
            if (jobId) {
                fetchJobStatus(jobId);
            }
        }, 5000); // Fetch status every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [jobId]);

    useEffect(() => {
        if (statStep === 4) { // Assuming step 4 is when the search is complete
            navigate('/download', { state: { downloadUrl } });
        }
    }, [statStep, navigate, downloadUrl]);

    const executeSearch = async ({ sessionId, model, resultsPerSearch, loopSet, term,domain }) => {
        sessionId = sessionId || "";
        const url = `https://api.kai-search.com/run_search`;
        const data = {
            term: term,
            num_results_per_search: parseInt(resultsPerSearch, 10),
            loop_set: loopSet,
            model: model,
            session_id: sessionId,
            email: userEmail,
            domain: domain
        };
        try {
            const response = await axios.post(url, data);
            const { job_id } = response.data;
            setJobId(job_id);
            setSearchStatus("Search initiated, waiting for results...");
        } catch (error) {
            console.error('Search failed:', error);
            setSearchStatus('Failed to initiate search');
        }
    };


    const fetchJobStatus = async (job_id) => {
        try {
            console.log("Fetching job status for ID:", job_id);
            const response = await axios.get(`https://api.kai-search.com/fetch_status/${job_id}`);
            setStatStep(response.data.step);
            setStatTxt(response.data.status);
            setDownloadUrl(response.data.url);
            setRespEmail(response.data.email);

        } catch (error) {
            console.error('Error fetching job status:', error);
            setStatTxt("Error: Unable to fetch status.");
        }
    };


    const totalSearches = 4; // There are 5 steps numbered 0 to 4
    const progress = (statStep / totalSearches) * 100;

    const emailTxt = respEmail ? `We will send the results to ${respEmail} if you disconnect.` : "";
    return (
        <div className="loading-screen">
            <div className="loading-body">
                <h2>Processing your search results...</h2>
                <div className="text-above-bar">
                    <p>Search Results</p>
                    <p className="percent-complete">{progress.toFixed(0)}% Complete</p>
                </div>
                <div className="progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progress}%` }}
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
                <p className="search-status">{statTxt}</p>
                </div>
            <p className="search-warn">Please do not leave this page while your search results load! {emailTxt} </p>
        </div>
    );
};


export default LoadingScreen;