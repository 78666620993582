import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useUser } from '../components/UserContext';  // Import useUser

const SignInPage = () => {
    const { signOut, user } = useAuthenticator((context) => [context.user, context.signOut]);
    const navigate = useNavigate();
    const { setUser } = useUser(); // Get setUser from your UserContext


    useEffect(() => {
        // If the user is signed in, navigate to the /search page
        if (user) {
            setUser(user);  // Set the user in the UserContext
            navigate('/search');
        }
    }, [user, navigate]);

    return (
        <div>
            {user && (
                <>
                    <h1>Welcome, {user.username}</h1>
                    <button onClick={signOut}>Sign out</button>
                </>
            )}
        </div>
    );
};

export default withAuthenticator(SignInPage);
