import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Cancel.css';

const Cancel = () => {
    const navigate = useNavigate();

    const goBackToSearch = () => {
        navigate('/search');  // Navigates back to the search page
    };

    return (
        <div className="cancel-page">
            <h1>Payment Canceled</h1>
            <p>Your payment was not completed. If this was an error, please try again.</p>
            <button onClick={goBackToSearch} className="back-button">
                Return to Search
            </button>
        </div>
    );
};

export default Cancel;
